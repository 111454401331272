@use "../abstracts/" as *;

.banner {
  position: relative;

  // background: url('../../assets/background/bg-banner.png') no-repeat;
  background-size: cover;

  .block-text {
    .heading {
      margin-bottom: 40px;

      span {
        background: linear-gradient(264.28deg, #dec7ff -38.2%, #5c27fe 103.12%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        position: relative;
      }

      span.s1 {
        &::after {
          content: "";
          width: 247.39px;
          height: 44.77px;
          position: absolute;
          bottom: -20px;
          left: -53px;

          background: url("../../assets/images/icon/Line.png") no-repeat;
        }
      }
    }

    .desc {
      padding-right: 130px;
      margin-bottom: 40px;
      font-size: 24px;

      @include desktop-1200 {
        padding: 0;
      }
    }

    .action-btn {
      span {
        padding: 18px 56px;
      }
    }
  }
  .banner__left {
    padding: 127px 0;

    .pay {
      margin-top: 130px;
      h6 {
        text-transform: none;
        margin-bottom: 20px;
      }
      .list {
        display: flex;
        align-items: center;

        p {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.3);
          margin-right: 16px;
        }

        ul {
          display: flex;
          li {
            margin-right: 20px;
            a {
              span {
                font-size: 33px;
                opacity: 0.3;
                transition: all 0.3s ease-in-out;
              }

              &:hover {
                span {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  .banner__right {
    padding: 83px 0;
    position: relative;
    .image {
      width: 551px;
      height: 551px;
      margin-left: auto;
      margin-right: 74px;
      box-shadow: 0px 16px 36px rgba(0, 0, 0, 0.25);
      border-radius: 130px;
      position: relative;
      background: rgba(255, 255, 255, 0.2);

      img {
        width: 90%;
        height: 90%;
        margin: 5%;
        object-fit: cover;
        border-radius: 50%;
        position: relative;
        z-index: 2;
      }

      &::before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 130px;
      }
      &::before {
        transform: rotate(-17deg);
      }
      &:after {
        transform: rotate(-7deg);
      }

      @include mobile {
        width: 100%;
        height: 200px;
        margin-left: auto;
        background: transparent;
        &::before {
          display: none;
        }
        &:after {
          display: none;
        }
      }
    }

    .price {
      display: flex;
      align-items: center;
      padding: 20px;
      background: $white;
      border-radius: 30px;
      position: absolute;
      top: 168px;
      right: 0;
      z-index: 3;
      animation: animate-1 15s linear 0s infinite;

      .icon {
        margin-right: 15px;
        filter: drop-shadow(0px 15px 20px rgba(164, 128, 255, 0.5));
      }
      p {
        color: #000000;
        font-size: 14px;
      }
      h5 {
        color: #000000;
      }
    }

    .owner {
      display: flex;
      align-items: center;
      padding: 20px;
      background: $white;
      border-radius: 30px;
      position: absolute;
      bottom: 206px;
      left: 4px;
      z-index: 3;
      animation: animate-3 5s linear 0s infinite;

      .image {
        margin-right: 15px;
        min-width: 70px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      p {
        color: #000000;
        font-size: 14px;
      }
      h5 {
        color: #000000;
      }
    }
  }
}

.banner.s2 {
  padding: 73px 0 120px;
  background: url("../../assets/images/background/bg-banner-2.png") no-repeat;

  .shape {
    top: -40%;
    left: -30%;

    &.right {
      left: auto;

      top: -85%;
    }
  }
  @include mobile {
    padding: 73px 0;
  }
  .block-text {
    .sub-heading {
      margin-bottom: 15px;
    }
    .heading {
      margin-bottom: 28px;
    }

    p {
      padding: 0 285px;
      font-size: 17px;
      font-size: 16px;
      @include desktop-1200 {
        padding: 0;
      }
    }

    .action-btn span {
      padding: 19px 55px;
    }
  }
  .bannerSwiper {
    margin-top: 100px;
  }

  .swiper-cards {
    .swiper-slide {
      background: transparent !important;
      box-shadow: none;
    }
  }

  .swiper-3d,
  .swiper-3d.swiper-css-mode .swiper-wrapper {
    perspective: 1800px;
  }

  .banner__main {
    margin-top: 21px;
    display: flex;

    @include mobile {
      flex-wrap: wrap;

      .card-box {
        &:first-child {
          margin-left: 20px;
          margin-top: 60px;
          transform: rotate(-7deg);
        }

        &:last-child {
          transform: translateX(34px) translateY(150px) rotate(4deg);
          z-index: 1;
        }
      }
    }
  }
}

.card-box {
  width: 397px;
  background: linear-gradient(
    89.96deg,
    rgba(255, 255, 255, 0.05) 0.03%,
    rgba(255, 255, 255, 0.008) 49.66%,
    rgba(255, 255, 255, 0.05) 99.96%
  );
  backdrop-filter: blur(2px);
  padding: 31px 23px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 100%;
  z-index: 3;
  @include mobile {
    width: 100%;
  }

  .top {
    margin-bottom: 20px;
    span {
      font-size: 30px;
      margin-right: 13px;
    }

    h6 {
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: -5px;

      &.price {
        background: linear-gradient(264.28deg, #dec7ff -38.2%, #5c27fe 103.12%),
          #ffffff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 20px;
      }
    }
  }

  .content {
    .image {
      height: 349px;
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 17px;
      @include mobile {
        height: auto;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s ease-in-out;
      }
    }

    .info {
      align-items: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 13px;
      }

      h6 {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 2px;
      }

      p {
        font-weight: 300;
        font-size: 14px;
      }
    }
  }

  &:hover {
    .content {
      .image {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}

.banner.s3 {
  .shape {
    top: -90%;

    &.right {
      top: -40%;
    }
  }
  .banner__left {
    padding: 158px 0;

    @include tablet {
      padding: 40px 0;
    }

    .block-text {
      .heading {
        margin-bottom: 19px;
      }

      .desc {
        margin-bottom: 45px;
      }

      .action-btn {
        margin-right: 15px;
        span {
          padding: 18px 65px;
        }

        &.s1 {
          span {
            padding: 17px 30px;
          }
        }
      }
    }

    .couter-list {
      margin-top: 123px;

      .couter-box {
        margin-right: 102px;
        max-width: 105px;

        &:last-child {
          margin-right: 0;
        }
        &::after {
          height: 70px;
          top: 0px;
          right: -59px;
        }
        .numb {
          span {
            font-size: 22px;
            position: absolute;
            right: -14px;
            top: 3px;
          }
        }
      }
    }
  }

  .banner__right {
    padding: 93px 108px 93px 77px;
    .image-1 {
      animation: animate-1 15s linear 0s infinite;
    }
    @include desktop-1200 {
      padding: 60px 0 80px;
      .image-1 {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
}

.banner-box {
  background: linear-gradient(
    89.96deg,
    rgba(255, 255, 255, 0.05) 0.03%,
    rgba(255, 255, 255, 0.008) 49.67%,
    rgba(255, 255, 255, 0.05) 99.96%
  );
  backdrop-filter: blur(2px);
  border-radius: 20px;
  padding: 20px 26px 0px 14px;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 14px;
    margin-bottom: 27px;
    .title {
      font-weight: 600;
      font-size: 26px;
    }

    .wishlist {
      display: flex;
      align-items: center;
      .icon {
        width: 30px;
        height: 30px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 50%;

        @include flex(center, center);
        margin-right: 5px;
      }
      p {
        margin-bottom: 0;
      }
    }
  }

  .main {
    display: flex;
    p {
      color: rgba(255, 255, 255, 0.5);
    }

    .info {
      margin-right: 72px;
      h4 {
        font-size: 26px;
        margin-top: 9px;
        margin-bottom: 5px;
      }
    }

    .coundown {
      p {
        margin-bottom: 8px;
      }
      .countdown__item {
        font-family: "Conthrax";
        font-weight: 600;
        font-size: 26px;
        color: #ffffff;
        margin-right: 50px;
        position: relative;
        text-align: center;

        &:last-child {
          margin: 0;
          &::before {
            display: none;
          }
        }

        &::after {
          position: absolute;
          bottom: -25px;
          left: 0;
          right: 0;
          font-weight: 300;
          font-size: 14px;
          font-family: "Readex Pro";
          color: rgba(255, 255, 255, 0.5);
        }

        &::before {
          content: "";
          position: absolute;
          right: -19px;
          top: 17px;
          width: 1px;
          height: 22px;
          background: rgba(255, 255, 255, 0.1);
        }

        &:nth-child(1) {
          &::after {
            content: "Days";
          }
        }
        &:nth-child(2) {
          &::after {
            content: "Hours";
          }
        }
        &:nth-child(3) {
          &::after {
            content: "Minutes";
          }
        }
        &:nth-child(4) {
          &::after {
            content: "Seconds";
          }
        }
      }
    }

    @include mobile {
      flex-wrap: wrap;
      .info {
        margin: 0 auto;
        text-align: center;
        width: 100%;
      }

      .coundown {
        margin: 30px auto;
        text-align: center;
      }
    }
  }

  .button {
    text-align: center;
    margin-top: 22px;

    a {
      margin-bottom: -40px;
      span {
        padding: 19px 24px 19px 62px;

        svg {
          margin-left: 40px;
        }
      }
    }
  }
}
